import { Component, inject, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Application, ApplicationCreditOfficer, ApplicationOwner, CurrencyInputValue, CustomerAngularEditorComponentValue, DocumentReviewDialogData, DocumentReviewDialogResult, GetAllPismoAccountStatusFn, GetApplicationCreditOfficerFn, GetApplicationOwnerFn, GetPismoAccountStatusReasonsFn, GetUserFn, GroupedDocument, isExternalUser, isInternalUser, PismoEditAccountDialogData, PismoEditAccountDialogResult, PismoGetAccountStatusReasonsResponse, PismoGetAccountStatusResponse, PismoRollbackAccountStatusFn, PismoUpdateAccountLimitFn, PismoUpdateAccountStatusFn, SlideToggleValue, User, UserandPriviledges } from '@portal-workspace/grow-shared-library';
import { Subscription, forkJoin, of, tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableModule } from '@angular/material/table';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ApplicationDialogService } from './application-dialog.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { MatExpansionModule } from "@angular/material/expansion";
import _ from 'lodash';
import { getUser, setupUntilDestroy, validateEmail } from '../component-utils';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { UntilDestroy } from '@ngneat/until-destroy';
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './document-review.dialog.html',
  styleUrls: ['./document-review.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    NgClass,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    CustomAngularEditorComponent,
    MatExpansionModule
]
})
export class DocumentReviewDialog implements OnInit {

  errorMessage: string = '';
  subscriptions: Subscription[] = [];
  displayedColumns = ['name', 'action'];
  loggedInUser: User | null = getUser();
  isExternalUser : boolean = isExternalUser(this.loggedInUser);
  getUserFn!: GetUserFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn
  isInternalUser = isInternalUser
  getApplicationCreditOfficerFn!: GetApplicationCreditOfficerFn

  data: DocumentReviewDialogData = inject(MAT_DIALOG_DATA);
  alltagStatus:string[] = [];
  filtertagStatus:string[] = [];
  dialogRef = inject(MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>);
  selectedGroupName = new SelectionModel<GroupedDocument>(true, []);
  salesEmail:string = "";
  creditEmail:string = "";
  submitterEmailList: string[] = [];
  submitterEmailListWithKeyName: { name: FormControl | null ; email: string; }[] = [];
  otherEmailList: string[] = [];
  application!: Application;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  submitterEmailError = false;
  otherEmailError = false;

  isInternalOnly:boolean= false
  pendingCount: number = 0;
  inProgressCount: number = 0;
  editorHeight = 200;
  isShowRequestReviewSection:Boolean = true;
  title: string = 'Review Documents/Conditions';
  notesPlaceholder:string = `Provide background information to support your request to review these conditions.\n\nOur team will respond to you as soon as possible.`
  allTags: GroupedDocument[] = [];
  filterTag: GroupedDocument[] = [];
  removedTag: GroupedDocument[] = [];
  selectableTags: GroupedDocument[] = [];
  formControlApplicationNotes!: FormControl<CustomerAngularEditorComponentValue>;
  formControlInternalOnly: FormControl<boolean | null>;
  formControlNotification: FormControl<boolean | null>;
  formControlCredit: FormControl<boolean | null>;
  formControlSales:FormControl<boolean | null>;
  formControlSettlements:FormControl<boolean | null>;
  // formControlOperation: FormControl<boolean | null>;
  formControlSubmitter: FormControl<boolean | null>;
  formControlOther: FormControl<boolean | null>;
  formControlSubmitterEmail!: FormControl<string | null>;
  formControlOtherEmail!: FormControl<string | null>;
  formControlAssignWorklist:FormControl<boolean | null>;

  // constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentReviewDialogData,
  //   private dialogRef: MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>,
  //   private formBuilder: FormBuilder,
  //   private toastService: PortalHotToastService) {
  //     groupNames

  //   // this.formControlEnabled.valueChanges.pipe(
  //   //   tap(r => {
  //   //     if (r === true) {
  //   //       this.formControlStatusReason.setValue(null)
  //   //       this.formControlStatusReason.clearValidators()
  //   //       this.formControlStatusReason.updateValueAndValidity()
  //   //       this.showStatusReason = false;
  //   //     } else {
  //   //       this.formControlStatusReason.setValidators([Validators.required])
  //   //       this.formControlStatusReason.updateValueAndValidity();
  //   //       this.showStatusReason = true;
  //   //     }
  //   //   })
  //   // ).subscribe();
  // }

  constructor(
    private dialogService: ApplicationDialogService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DocumentReviewDialogData,
    private matDialogRef: MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private applicationService:ApplicationService,
    private toastService: PortalHotToastService,
  ) {
    this.isShowRequestReviewSection = dialogData.isShowRequestReviewSection
    this.formControlApplicationNotes = this.formBuilder.control('',)
    this.formControlInternalOnly = this.formBuilder.control(false,)
    this.formControlNotification = this.formBuilder.control(false,)
    this.formControlCredit = this.formBuilder.control(false,)
    this.formControlSales = this.formBuilder.control(this.isShowRequestReviewSection ? true : false,)
    this.formControlSettlements = this.formBuilder.control(false,)
    this.formControlSubmitter = this.formBuilder.control(this.isShowRequestReviewSection ? true : false,)
    this.formControlOther = this.formBuilder.control(true,)
    this.formControlSubmitterEmail = this.formBuilder.control(null);
    this.formControlOtherEmail = this.formBuilder.control(null);
    this.formControlAssignWorklist = this.formBuilder.control(false,)
    this.allTags = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    // this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted');
    this.title = dialogData.title
    if(!this.isShowRequestReviewSection){
      this.notesPlaceholder = "";
      this.editorHeight = 100
    }
  }

  ngOnInit() {
    setupUntilDestroy(this)
    // this.alltagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted');
    const alltagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted' && status !== '');
    this.alltagStatus = alltagStatus.sort((a, b) => {
      const order = ['Pending', 'In Progress']; // Define the preferred order
      const indexA = order.indexOf(a);
      const indexB = order.indexOf(b);

      if (indexA !== -1 || indexB !== -1) {
        return (indexA === -1 ? 2 : indexA) - (indexB === -1 ? 2 : indexB);
      }
    
      return 0;
    });
    // this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted');
    this.data.groupNames.forEach(element => {
      this.selectedGroupName.select(element)
    });

    this.getUserFn = this.authService.getUserFn;
    this.getApplicationOwnerFn = this.applicationService.getApplicationOwnerFn;
    this.getApplicationCreditOfficerFn = this.applicationService.getApplicationCreditOfficerFn
    this.application=this.dialogData.application;
     
    if(this.isShowRequestReviewSection){
      this.addEmail(this.application.AppInfo.UserEmail,this.formControlSubmitter)
      if(!this.salesEmail && this.application.AppInfoSalesforceID){
        this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
        .pipe(this.toastService.spinnerObservable())
        .subscribe(
          (Application: ApplicationOwner | null) => {
            if (Application && Application.Email) {
              const email = Application.Email;
              this.salesEmail = email 
              this.addEmail(email,this.formControlSales)
            }else {
              this.salesEmail = "sales@dynamoney.com"
              this.addEmail(this.salesEmail,this.formControlSales)
            }
          },
           
        );
      }else if(this.salesEmail){
        this.addEmail(this.salesEmail,this.formControlSales)
      }else {
        this.salesEmail = "sales@dynamoney.com"
        this.addEmail(this.salesEmail,this.formControlSales)
      }
    }
     
    this.subscriptions.push(this.formControlCredit.valueChanges.pipe(
      tap(arg => {
         if(arg){
          if(!this.creditEmail && _.has(this.application, "AppInfoSalesforceID")  && this.application.AppInfoSalesforceID){
            this.getApplicationCreditOfficerFn(this.application.AppInfoSalesforceID)
            .pipe(this.toastService.spinnerObservable())
            .subscribe(
              (Application: ApplicationCreditOfficer | null) => {
                if (Application && Application.CreditOfficerEmail) {
                  const email = Application.CreditOfficerEmail;
                  console.log("creditEmail",Application.CreditOfficerEmail)
                  this.creditEmail = email 
                  this.addEmail(email,this.formControlCredit)
                }else {
                  this.creditEmail = "credit@dynamoney.com";
                  this.addEmail(this.creditEmail,this.formControlCredit)
                }
              },
               
            );
          }else if(this.creditEmail){
            this.addEmail(this.creditEmail,this.formControlCredit)
          }else {
            this.creditEmail = "credit@dynamoney.com";
            this.addEmail(this.creditEmail,this.formControlCredit)
          }
          

         }else {
          if(this.creditEmail){
            this.removeEmail(this.creditEmail)
          }
           
         }
         
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSettlements.valueChanges.pipe(
      tap(arg => {
         if(arg){
          this.addEmail("contract@dynamoney.com",this.formControlSettlements)
         }else {
            this.removeEmail("contract@dynamoney.com")
         }
         
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSales.valueChanges.pipe(
      tap(arg => {
         if(arg){
          if(!this.salesEmail && this.application.AppInfoSalesforceID){
            this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
            .pipe(this.toastService.spinnerObservable())
            .subscribe(
              (Application: ApplicationOwner | null) => {
                if (Application && Application.Email) {
                  const email = Application.Email;
                  this.salesEmail = email 
                  this.addEmail(email,this.formControlSales)
                }else {
                  this.salesEmail = "sales@dynamoney.com"
                  this.addEmail(this.salesEmail,this.formControlSales)
                }
              },
               
            );
          }else if(this.salesEmail){
            this.addEmail(this.salesEmail,this.formControlSales)
          }else {
            this.salesEmail = "sales@dynamoney.com"
            this.addEmail(this.salesEmail,this.formControlSales)
          }

         }else {
          if(this.salesEmail){
            this.removeEmail(this.salesEmail)
          }
           
         }
         
      })
    ).subscribe());

    this.subscriptions.push(this.formControlInternalOnly.valueChanges.pipe(
      tap(value => {
         if(value){
          this.isInternalOnly = true
          this.formControlSubmitter.setValue(false);
          this.removeEmail(this.application.AppInfo.UserEmail)
         }else{
          this.isInternalOnly = false
         }
         
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSubmitter.valueChanges.pipe(
      tap(value => {
         if(value){
          this.formControlInternalOnly.setValue(false);
          this.addEmail(this.application.AppInfo.UserEmail,this.formControlSubmitter)
         }else {
          this.removeEmail(this.application.AppInfo.UserEmail)
         }
         
      })
    ).subscribe());
    this.subscriptions.push(
      this.formControlNotification.valueChanges.subscribe((value) => {
        // value === true ? (this.showNotificationOptions = true) : (this.showNotificationOptions = false);
        if(value === true) {
          // this.showNotificationOptions = true
          this.formControlSubmitter.setValue(true)
          this.formControlSales.setValue(true)
          this.formControlSettlements.setValue(true)
        } else {
          // this.showNotificationOptions = false
          this.formControlCredit.setValue(false)
          this.formControlSubmitter.setValue(false)
          this.formControlOther.setValue(false)
        }
      }),
      this.formControlSubmitterEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.submitterEmailError = false;
        }
      }),
      this.formControlOtherEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.otherEmailError = false;
        }
      })
    )
    // this.updateStatusCounts();
  }

  onPanelOpen() {
    if(this.filtertagStatus.length === 0 && this.filterTag.length === 0) {
      this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted' && status !== "");
      this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    }
    this.updateStatusCounts()
  }

  updateStatusCounts() {
     
    this.pendingCount = this.filterTag.filter(tag  => tag.status === 'Pending').length;
    this.inProgressCount = this.filterTag.filter(tag => tag.status === 'In Progress').length;
  }

  addEmail(email:string,control:FormControl | null): void {
    if (!_.includes(this.submitterEmailList, email)) {
      this.submitterEmailList.push(email);
      if(control){
        this.submitterEmailListWithKeyName.push({"name" : control,"email"  : email})
      }
      
    }
  }
  removeEmail(email:string): void{
    if (email) {
      _.pull(this.submitterEmailList, email);
       const result = _.find(this.submitterEmailListWithKeyName, { email: email });
       if(result && result.name){
        result.name.setValue(false)
       }
       
    }
  }

  remove(tag: GroupedDocument): void {
    _.pull(this.filterTag, tag);
    this.removedTag.push(tag)
    this.updateStatusCounts();
    // const index = this.filterTag.indexOf(tag);
    // console.log("index",index)
    // console.log("this.filterTag",this.filterTag)
    // if (index >= 0) {
    //   this.filterTag.splice(index, 1);
    // }
    // this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
  }
 
  onSelectionChanged($event: MatCheckboxChange, element: GroupedDocument) {
   
    if($event.checked) {
      this.selectedGroupName.select(element)
    } else {
      this.selectedGroupName.deselect(element)
    }
  }
  onStatusSelectionChanged($event: MatCheckboxChange, element: string) {
    if($event.checked) {
      this.filtertagStatus.push(element)
    } else {
      _.pull(this.filtertagStatus, element);
       
    }
    
    this.filterTag = this.allTags.filter(item => this.filtertagStatus.includes(item.status) && !this.removedTag.includes(item));
    this.updateStatusCounts();
  }
  
  // toggleApplicantPrivacyConsent($event: MatSlideToggleChange, element: GroupedDocument) {
     
  //   if($event.checked) {
  //     this.selectedGroupName.select(element)
  //   } else {
  //     this.selectedGroupName.deselect(element)
  //   }
  // }
  refresh(){
    // this.filterTag = this.allTags
    this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    this.removedTag = []
    // this.filtertagStatus = this.alltagStatus
    this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted' && status !== '');
    this.updateStatusCounts();
  }
  onSave() {  
    const validNote = this.formControlApplicationNotes.value === '<p></p>' || null ? false : true;
    this.dialogRef.close({
      valid: true,
      groupNames:this.filterTag,
      //groupNames:this.selectedGroupName.selected,
      documentNotes: validNote ? this.formControlApplicationNotes.value : null,
      isInternalUser: this.formControlInternalOnly.value,
      isNotificationSelect: this.formControlNotification.value,
      isCreditSelect: this.formControlCredit.value,
      isSalesSelect:this.formControlSales.value,
      isSettlement:this.formControlSettlements.value,
      isSubmitterSelect: this.formControlSubmitter.value,
      submitterEmailList: this.submitterEmailList,
      isAssignWorklist: this.formControlAssignWorklist.value,
    });
  }

  onCancel(event: Event) {
    this.dialogRef.close({type: 'cancel'});
  }


  
  addSubmitterEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.addEmail(value,null)
      }
      // Clear the input value
      event.chipInput!.clear();
      this.submitterEmailError = false;
    } else if (value) {
      this.submitterEmailError = true;
    }
  }
 
}
