import { VerimotoInspectionTypeSelection } from "./verimoto.domain";

export type EnableTilesConfigType = 'enabled' | 'disabled' | 'terminated' | 'internal';

export type EnableTilesConfig = {
  AssetFinance: EnableTilesConfigType,
  BusinessLoans: EnableTilesConfigType,
  TradeFinance: EnableTilesConfigType,
  Consumer: EnableTilesConfigType,
  InsurancePremium: EnableTilesConfigType,
  Commercial: EnableTilesConfigType,
  BusinessOverdraft: EnableTilesConfigType,
  CorporateLoans: EnableTilesConfigType,
}

export type ApplicationSubmissionType = {
  disabledMessage: string,
  internalAccessMessage: string,
} & EnableTilesConfig;

export type VerimotoLenderType = {
  name: string;
  guid: string;
}

export type PortalEnvironmentType =  {
  production: boolean,
  enableServiceWorker: boolean,
  gitTag: string,
  gitHash: string,
  api2Host: string,
  agmApiKey: string,
  submitApplicationTermsAndConditionsUrl: string,
  helpCenterUrl: string,
  facebookUrl: string,
  linkedinUrl: string,
  fxApiKey: string,
  invoiceFinanceUrl: string,
  contactUsUrl: string,
  brokerProductGuideUrl: string,
  assetProductGuideUrl: string,
  bankStatements: string,
  idVerify: string,
  GrowFinanceGroupSalesforceId: string,
  OverdraftCustomerRecordTypeId: string,
  GrowFinanceGroupName: string,
  appInsightsKey: string | null,
  autoRefreshToken: boolean,
  applicationSubmission: ApplicationSubmissionType,
  directSaleSubmission: ApplicationSubmissionType,
  localStorage: {
    encryptionKey: string,
  },
  verimoto: {
    lender: VerimotoLenderType,
    verimotoInspectionTypes: VerimotoInspectionTypeSelection[]
  },
  directSaleCompanyId: number,
  directSaleUserId: number,
  directSaleUserEmail: string,
  directSaleUserFirstName: string,
  directSaleUserLastName: string,
};
