<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->

@if (!((store.value$ | async)?.error)) {
  <div class="applications-page container-fluid">
    @if (this.promo?.showMessage==true) {
      <div class="promo">
        <message-box type="promo-style2" [url]="this.promo?.link||''" (close)="closePromo($event)">{{this.promo?.message}}</message-box>
      </div>
    }
    <div class="header-bar">
      <div class="row">
        <div class="col">
          <div class="application-status mb-2" fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column">
            <data-box [selected]="selectedApplicationState === 'under-review'" (click)="onUnderReviewApplicationStateFilter()" title="Under Review" type="black" data="{{totalApplicationsUnderReview}}" icon="file-find-outline"></data-box>
            <data-box [selected]="selectedApplicationState === 'in-settlement'" (click)="onInSettlementApplicationStateFilter()" title="In Settlement" type="black" data="{{totalApplicationsInSettlement}}" icon="bank-outline"></data-box>
            <data-box [selected]="selectedApplicationState === 'closed-won'" (click)="onClosedWonApplicationStateFilter()" title="Closed Won" type="green" data="{{totalApplicationsClosedWon}}" icon="trophy-outline"></data-box>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" fxLayout.lt-md="column">
            <mat-form-field class="search" fxFlex="1 1 400px" fxFlex.lt-md="1 1 50px" fxFlexAlign="start">
              <input matInput #searchText class="ml-4" (change)="onSearch($event)" type="text" placeholder="Search application by ID or Name">
              <span class="mdi mdi-magnify" matPrefix></span>
            </mat-form-field>
            <div class="select w-lt-md-100"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutAlign.lt-md="space-between center">
              <mat-form-field>
                <mat-select [(value)]="selectedApplicationType">
                  @for (applicationType of applicationTypes; track applicationType) {
                    <mat-option
                      [value]="applicationType"
                      (click)="onApplicationTypeSelected(applicationType)">
                      <application-type-icon [applicationType]="applicationType.type" ></application-type-icon>
                      {{applicationType.name}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <span [matTooltip]="'Refresh application Listing'" class="refresh-button mdi mdi-refresh cursor-pointer" (click)="refresh()"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row separator-top">
      <div class="col body">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSort($event)">
            <ng-container matColumnDef="appId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>App ID</th>
              <td class="col-app-id" mat-cell *matCellDef="let application">
                <application-type-icon [applicationType]="application.ApplicationType"></application-type-icon> {{application.BrokerAppId}}<br/>
                <span class="date-text">{{getApplicationCreateTime(application)}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="appName">
              <th class="col-appName" mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>App Name</span>
              </th>
              <td mat-cell *matCellDef="let application">
                @if (application.Source == 'external') {
                  <span class="mr-1 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
                }
                @if (application.Source == 'direct') {
                  <span class="mr-1 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
                }
                {{getApplicantName(application)}}<br/>
                @if (showBrokerName()) {
                  <span class="date-text">{{application.BrokerName}}</span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStatus(application) ?? ''"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="stage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stage</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStage(application)"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
              <td mat-cell *matCellDef="let application" class="cell">
                <span class="mdi mdi-dots-vertical cursor-pointer" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"></span>
                <mat-menu #menu="matMenu">
                  @if (application.Status == 'Draft') {
                    <button mat-menu-item (click)="onDelete(application.ApplicationId)"  class="pr-0">
                      <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div>Delete</div>
                        <div><span class="mdi mdi-trash-can-outline"></span></div>
                      </div>
                    </button>
                  }
                  @if (application.Status !== 'Draft') {
                    <button mat-menu-item (click)="onCopyApplication(application.ApplicationId)" class="pr-0">
                      <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div>Copy</div>
                        <div><span class="mdi mdi-content-copy"></span></div>
                      </div>
                    </button>
                  }
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr class="cursor-pointer" (click)="onApplicationClick($event, application)" mat-row *matRowDef="let application; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
          <div class="footnote mat-caption mt-2"
            fxLayoutAlign.lt-sm="center center">
            Only applications in the last 12 months are listed
          </div>
          <div class="footnote mdi mdi-magnify mat-caption cursor-pointer mt-2"
            fxLayoutAlign.lt-sm="center center" (click)="onClickAllApplication($event)">
            <u>Search for older applications</u>
          </div>
        </div>
      </div>
    </div>
  </div>
}
