import {
  ApiResponse,
  AzureStorageDocument,
  Base64File,
  DocumentTag,
  InteflowStatusRecord, NotNullable, Nullable,
  OriginatorBusiness,
  PaymentPeriodType,
  RiskRatingInterface,
  SortTypes,
  User
} from './types.domain';
import {EncodingTypes} from './types.domain';
import {AssetFinanceTier1, AssetFinanceTier2, AssetFinanceTier3, AssetFinanceTier4} from './admin-service.domain';
import { UserSelectionValue } from './component/user-selection-component.domain';
import {IndividualWithResult, ThirdPartyEntity} from './digital-id-service.domain';
import {BusinessNumberSearchValue} from './component/business-number-search-component.domain';
import { PrivateSaleType, TransactionType } from './component/transaction-type-component.domain';
import {Observable} from 'rxjs';
import { PayloadApiResponse } from './types.domain';
import {Response} from 'request';
import {TruckGrossVehicleWeight} from './redbook-service.domain';
import {TransactionTypeRateValue} from './component/transaction-type-rate-component.domain';
import { CurrencyInputValue } from './component/currency-input-component.domain';
import { BsbComponentValue } from './component/bsb-component.domain';
import {DscrIndustry, DscrIndustryEbitda} from '../const';
import {CustomerApplicantTypeValue} from "./component/customer-applicant-component.domain";
import { AssetExistingFinanceCommitmentValue, AssetPurposeValue } from './component/asset-purpose-selection-component.domain';
import {LtvSelectionType} from "./component/ltv-selection-component.domain";
import {SecurityTypeSelectionType} from "./component/security-type-selection-component.domain";
import {SecurityTypeRateValue} from "./component/security-type-rate-component.domain";
import {MaritialStatusSelectionType} from "./component/maritial-status-selection-component.domain";
import {EmploymentStatusType} from "./component/employment-status-selection-component.domain";
import {CurrencyWithPeriodValue} from "./component/currency-with-period-component.domain";
import {SalesforceContactSameAccountSelectionValue} from "./component/salesforce-contact-same-account-selection-component.domain";
import { TitleSelectionValue } from './component/title-selection-component.domain';
import { FinanceType } from './component/finance-type-component.domain';
import { IdentificationComponentValue } from './component/identification-component.domain';
import { RepaymentTypeValue } from './component/repayment-type-component.domain';
import {IncomePeriodType, IncomeSelectionPeriod} from "./component/income-component.domain";
import { RepaymentFrequencyType } from '../app-calculator';

export type GetRbaRateFn = () => Observable<number>;

export type StageNameType = 'Application Received' | 'Closed Lost' | 'Closed Won' | 'Credit Approved' |
  'Deal Preparation' | 'Docs Out' | 'Documentation' | 'Draft' | 'Lead' | 'More Information' | 'Proposal' |
  'Prospect' | 'QA' | 'Settlement' | 'Settlement Pending' | 'Submitted to Credit' | 'Waiting for Bank Statement'
  ;

export interface LvrCalculatorInput { // input required to update Lvr Calculator Value
  assets: {description: string, value: number}[],
  liabilities: {description: string, value: number}[],
  proposedLoan: number, // this is application's PricingDetails's AmountFinanced
}

export interface LvrCalculatorValue extends LvrCalculatorInput { // Lvr Calculator value + computed values
  totalAssets: number,
  totalLiabilities: number,
  currentLvrPercent: number | null,
  proposedLvrPercent: number | null,
}

export interface ConsumerDscrCalculatorInput {
  maritialStatus: MaritialStatusSelectionType,
  numberOfDependents: number,
  employmentStatus: EmploymentStatusType,
  regularIncome: NotNullable<CurrencyWithPeriodValue>,
  hasSpouseOrPartner: boolean,
  spouseRegularIncome: NotNullable<CurrencyWithPeriodValue>,
  homeLoans: number,
  carLoans: number,
  totalCrediCardLimits: number,
  electricityAndGas: number,
  otherUtilities: number,
  education: number,
  // repayment: number,
  // postcode: number,
  personalLoan: number,
  groceries: number,
  otherLoan: number,
  insurance: number,
  shareOfMonthlyLivingExpenses: number,
}

export interface ConsumerDscrCalculatorValue extends ConsumerDscrCalculatorInput {
  repayment: number,
  paymentPeriod: PaymentPeriodType,
  monthlyPayment: number,
  postcode: number,
}

export interface DscrCalculatorInput {
  customerName: string,
  loanAmount: number, // this is application's Loan Amount
  industry: DscrIndustry | null,
  industryEbitda: DscrIndustryEbitda | null,
  adjustmentPercent: number,
  monthlySalesAverage3Months: number,
  monthlySalesAverage6Months: number,
  monthlySalesAverage12Months: number,
  averageMonthlySalesAdopted: number,
  salesAdjustment: number,
  averageMonthlySalesAdoptedAfterAdjustment: number,
  existingDebtCommitmentsMonthly: number,
  atoPaymentPlanMonthly: number,
  additionalCommitments: number,
  proposedCommitmentsMonthly: number,
}

export interface DscrCalculatorValue extends DscrCalculatorInput {
  marginAdoptedPercent: number,
  cashAvailableForDebtServiceMonthly: number,
  // proposedCommitmentsMonthly: number,
  totalFinancingCommitmentsMonthly: number,
  debtServiceCoverRatio: number,
  proposedLoanAmount: number,
}




export interface SaveQuoteResult {
  quoteId: number ;
}

export interface Quote {
  LoanAmount: string,
  RepaymentFrequency: string,
  LoanTerm: number,
  Residual?: number,
  BrokeragePercentage?: number,
  FacilityEstablishmentPercentage?: number,
  BrokerageAmount: number,
  QuoteAsJson: string,
}

export type ApplicationFilterType = 'all' | 'under-review' | 'in-settlement' | 'closed-won';

export type ApplicationSortTypes = SortTypes<'ApplicationId' | 'BrokerAppId' | 'CompanyName' | 'Status' | 'AppInfoStageName' | 'CreateTime' | 'BrokerName'>;

export interface SimplifiedApplication {
  ApplicationId: number,
  Source: ApplicationSource,
  BrokerAppId: string,
  ApplicationType: ApplicationTypes,
  Status: string,
  CreateTime: string,
  CommercialEntityLegalName: string | null,
  IndividualGivenName: string | null,
  IndividualSurName: string | null,
  SalesforceId: string | null,
  BrokerSalesforceID: string | null,
  StageName: string,
  CompanyName: string,
  BrokerName: string,
  PricingDetails: { LoanAmount: number }
  // InteflowStatus: any
}

export interface RatecardDetails {
  ABNorGSTAge: {To?: string, From?: string, Rate: string}[];
  AdversOnFile: number;
  ApplicationType: 'AssetFinance' | 'TradeFinance' | 'InsurancePremium' | 'BusinessLoans' | 'BusinessOverdraft' | 'Consumer' | 'CorporateLoans';
  ApplicationCategory: {Cat: string, Rate: number}[];
  AssetConditionUsed: number;
  AssetFinanceTier1: AssetFinanceTier1;
  AssetFinanceTier2: AssetFinanceTier2;
  AssetFinanceTier3: AssetFinanceTier3;
  AssetFinanceTier4: AssetFinanceTier4;
  AssetType: {Cat: string, Type: string, Rate: number}[];
  AssetCategory: {Cat: string, Rate: number}[];
  BaseInterestRate: number;
  Brokerage: {To?: string, From?: string, Rate: string}[];
  CarsPropertyOwner: 0 | 1;
  CreateTime: string;
  DocFee: {From?: string, To?: string, Rate: string}[];
  EOTAge: {From?: string, To?: string, Rate: string}[];
  GSTAge24Months: number,
  LoanAmount: {From?: string, To?: string, Rate: string}[];
  LoanTerms: {From?: string, To?: string, Rate: string}[];
  LowEquifaxScore: number;
  LowDepositUplift: number;
  Name: string;
  NoPropertyOwnership: 0 | 1;
  CapRate: number;
  Truck12T: number;
  NoAssetBacked: number;
  TransactionTypeRate: TransactionTypeRateValue;
  PrivateSale: number;
  PrivateSaleDocFee: number;
  RateDetailId: number;
  RatecardId: number;
  UpdateTime: string;
  PropertyOwner:number;
  NoPropertyOwner:number;
  FacilityFee: number;
  BrokerShareFacilityFee: number;
  DirectorScoreRate: number;
  Hybrid: number;
  CarsAndCaravans: {From?: string, To?: string, Rate: string}[];
  Motorbikes: {From?: string, To?: string, Rate: string}[];
  SolarSystems: {From?: string, To?: string, Rate: string}[];
  NonPropertyOwnerBetween500And600Rate: number;
  rbaRate: number;
  SecurityType?: SecurityTypeRateValue;
  MonthlyAccountKeepingFee: number;
  MaxBrokerRateDiscount: number;
  CreditAssitanceFee: number;
  PpsrFee: number;
  MaxFacilityEstablishmentFee: number;
}

// export interface InsuranceProduct {
//   InsuranceProductId: number,
//   ProductName: string,
// }

export interface BankStatement {
  filename: string;
  id: string;
}

export type RoleTypes = 'Applicant' | 'Director' | 'Guarantor' | 'Partner' | 'Shareholder' | 'SoleTrader' | 'Trustee' | 'Spouse' | null | string;
export type SignerRoleTypes = 'Signer' | 'Guarantor' | 'GuarantorSigner' | 'Others' | 'CorporateGuarantor' | null;

export interface AppDocument {
  name: string, type: string, salesforceFileId?: string, sharepointId?: string, isPPSRDoc?: boolean,
  data: string,
  encoding?: EncodingTypes,
}

export interface DocumentResult {
  name: string,
  result:  {id: string, success: boolean, errors: string[]},
  salesforceFileId?: string,
}

export interface Reference {
  CompanyName: string,
  ContactPerson: string,
  Telephone: string,
  Type: string,
}
// export interface Background {
//   whatDoesTheBusinessSell?: string | null,
//   whatDoesTheBusinessSellTo?: string| null,
//   whatDoesTheBusinessBuyFrom?: string| null
// }

export interface Contact {
  Email: string,
  GivenName: string,
  MiddleName: string,
  Mobile: string,
  SurName: string,
  Title: string,
  Telephone: string,
  AreaCode: string,
  isManual: boolean,
}

export type UnformattedAddress = Address;
export interface Address {
  UnitNumber?: string,
  Suburb: string,
  State: string,
  Postcode: string,
  StreetName: string,
  StreetNumber: string,
  UnformattedAddress: string,
  IsManualInput: boolean,
  StreetType: string,
}

export type RawAddress = Address2;
export interface Address2 {
  UnitNumber?: string,
  Suburb: string,
  State: string,
  Postcode: string,
  StreetName: string,
  StreetNumber: string,
  RawAddress: string,
  IsManualInput: boolean,
  StreetType: string,
}

export interface Individual {
  AddressofPropertyOwned?: Address,
  DoB: string,
  Email: string,
  Gender: string,
  GivenName: string,
  GuarantorFlag: boolean,
  HomeAddress: Address,
  MiddleName: string,
  MobileNumber: string,
  deleted?: boolean;  // true means deleted, else not deleted
  disabled?: boolean;
  MortgageBalance?: number,
  PrivacyConsent: boolean,
  PropertyOwnerFlag: boolean,
  PropertyValue?: number,
  Role: RoleTypes,
  SignerRole: SignerRoleTypes,
  SurName: string,
  Title: string,
  id?: string,
  sfContactId?: string,
  thirdPartyEntity?: ThirdPartyEntity,
  additionalRoles?: IndividualAdditionalRole[],
  isNameOrDobChanged?: boolean;
}

export interface IndividualAdditionalRole {
  business: BusinessNumberSearchValue,
  signerRole: SignerRoleTypes,
}

export interface ConsumerIndividual extends Individual {
  Employer: string,
  EmployerContact: string,
  EmploymentStatus: string, // EmploymentStatus.name
  Income: number,
  IncomeFrequency: IncomePeriodType,  // IncomePeriodType
  MaritalStatus: string,  // MaritialStatus.name
  NumberofDependants: number,
  TimeatAddress: number,
  PreviousAddress?: Address,
  TimeatCurrentEmployment: number,
  PreviousEmployer?: string,
  PreviousEmployerContact?: string,
  Identification?: IdentificationComponentValue
}
export interface IndividualPropertyAsset {
  AssetType: string, Value: string, Address: Address
}
export interface IndividualOtherAsset {
  AssetType: string, Value: string, Description: string
}
export interface IndividualMortgageLiability {
  Type: string, Value: string, Address: Address,
}
export interface IndividualOtherLiability {
  Type: string, Value: string, Financier: string
}
export interface IndividualWithAssetsAndLiabilities extends Individual {
  Assets?: (IndividualPropertyAsset | IndividualOtherAsset)[],
  Liabilities?: (IndividualMortgageLiability | IndividualOtherLiability)[],
}

export interface CommercialEntity {
  ABN: string,
  ACN: string,
  AreaCode: string,
  BusinessCategory: string,
  EntityType: 'P/L' | 'PTNR' | 'PTY' | 'SLTR' | 'TRST' | null,
  IndustrySector: string,
  LegalName: string,
  OperateatCommercialAddressFlag: boolean,
  PhoneNumber: string,
  PrimaryIndustry: string,
  PrinciplePlaceofBusiness: Address,
  Revenue: number,
  TradingName: string,
  manualEntry: boolean,
  Type: string,
  ABNAgeMonths: number,
}


export interface AssetSpec {
  assetExistingFinanceCommitment: AssetExistingFinanceCommitmentValue | null ;
  assetPurposeDescription: string | null ;
  assetPurpose: AssetPurposeValue | null;
  make: string,
  family: string,
  year: number | string,
  vehicle: string,
  LVR: string | null,
  description: string,
  avgretail?: number,

  goodretail?: number,
  newPrice?: number,
  OtherCar: boolean,
  truckGrossVehicleWeight?: TruckGrossVehicleWeight,
  descriptionSf?: string,
  vehicletype?:string,
  rbid?:number,
  rbc?:number
  newprice?:number,
  month?:number,
  makecode?:string,
  importflag?:string,
  goodwhs?: number,
  avgwhs?: number,
  bodystyle?: string,
}

export type ApplicationTypes = 'AssetFinance' | 'BusinessLoans' | 'TradeFinance' | 'Consumer' | 'InsurancePremium' | 'Commercial' | 'BusinessOverdraft' | 'CorporateLoans';
export type CustomContentLoaderType = 'facebook' | 'list' | 'bullet' | 'custom';
export type AllApplicationTypes = 'All' | ApplicationTypes;
export type PublishedStatus = 'All' | true | false;

export type AssetFinanceProductType = 'FAST DOC' | 'EASY DOC' | 'FULL DOC';
export interface AssetFinanceApplication {
  ApplicationType: 'AssetFinance',
  isDeleted:boolean,
  Source: ApplicationSource,
  GrowRiskRatingData?: RiskRatingInterface | null,
  AppInfoSalesforceID?:string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerName: string,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    AssetCategory: string,
    AssetType: string,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BrokerflowDocumentID?: string,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
    isSwitchToEasyDoc: boolean,
    productType: AssetFinanceProductType,
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicationLocation: { lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: AssetSpec,
  AuthorisedSignatory: string | null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: SearchCompanyByABNResult,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[], // any[],//AzureStorageDocument[],  // TODO: find out why this is type to any[], shouldn't be that way.
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: Individual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies: null,
  PricingDetails: {
    FacilityEstablishmentFee: number,
    FacilityEstablishmentFeePercent: number,
    AdverseOnFile: 'Yes' | 'No',
    AssetCondition: 'New' | 'Used',
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    BrokerOriginationFee: number,
    Brokerage: number,
    Deposit: number,
    DocFee: number,
    DocFeeFinanced: string,
    EquifaxScoreAbove600: 'Yes' | 'No',
    InvoiceAmount: number,
    AmountFinance: number,
    LoanAmount: number,
    LoanTerm: number,
    PaymentPeriod: PaymentPeriodType | null,
    PrivateSale: PrivateSaleType,
    PropertyOwner: 'Yes' | 'No',
    Margin: number;
    Rate: number,
    CreditRateAdjustment?: number,
    Repayment: number,
    PaymentType: string,
    BrokerageAmount: number, // from SF
    BrokerOriginationFeeSf: number, // from SF
    DocFeeSf: number, // from SF
    RateSf: number, // from SF
    SpecialConditions?: string, // from SF,
    TransactionType?: TransactionType,
    PreviousLoan: 'Yes' | 'No',
    LastUpdatedByUser?: {id: number, name: string} | null,
    RepaymentType?: string
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string, // Draft
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[];
  PreviousBusiness: BusinessNumberSearchValue;
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

export interface CommercialFinanceApplication {
  ApplicationType: 'Commercial',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  AppInfoSalesforceID?: string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    AssetCategory: string,
    AssetType: string,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BrokerflowDocumentID?: string,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicationLocation: { lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: AssetSpec,
  AuthorisedSignatory: string | null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: null,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: Individual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies: null,
  PricingDetails: {
    AdverseOnFile: 'Yes' | 'No',
    AssetCondition: 'New' | 'Used',
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    BrokerOriginationFee: number,
    Brokerage: number,
    Deposit: number,
    DocFee: number,
    DocFeeFinanced: string,
    EquifaxScoreAbove600: 'Yes' | 'No',
    InvoiceAmount: number,
    LoanAmount: number,
    LoanTerm: number,
    PaymentPeriod: PaymentPeriodType | null,
    PrivateSale: 'Yes' | 'No',
    PropertyOwner: 'Yes' | 'No',
    Margin: number;
    Rate: number,
    Repayment: number,
    PaymentType: string,
    BrokerageAmount: number,
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string, // Draft
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[],
  PreviousBusiness: BusinessNumberSearchValue,
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

export interface BusinessLoansApplication {
  ApplicationType: 'BusinessLoans',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  UserId: number,
  AppInfoSalesforceID?:string,
  AppInfoStageName?: StageNameType | null,
  AppInfoBrokerSalesforceID?: string,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  LvrCalculatorValue: LvrCalculatorValue,
  DscrCalculatorValue: DscrCalculatorValue,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    AssetCategory: string,
    AssetType: string,
    BrokerAppID: string,
    BrokerflowDocumentID?: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    LoanPurpose: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: {lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: null,
  AuthorisedSignatory: null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: SearchCompanyByABNResult,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: Individual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: false,
  Policies: null,
  PricingDetails: {
    FacilityEstablishmentFee: number,
    FacilityEstablishmentFeePercent: number,
    AdverseOnFile: 'Yes' | 'No',
    BankStatementSubmitted: string,
    BrokerOriginationFee: number,
    Brokerage: number,
    DocFee: number,
    DocFeeFinanced: string,
    EquifaxScoreAbove600: 'Yes' | 'No',
    InvoiceAmount: number,
    LoanAmount: number,
    AmountFinance?: number,  // filled by SF pricing details callback
    LoanTerm: number,
    PaymentPeriod: PaymentPeriodType | null,
    PropertyOwner: 'Yes' | 'No',
    Margin: number;
    Rate: number,
    CreditRateAdjustment?: number,
    Repayment: number,
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    DirectorScoreRate: 'Yes' | 'No',
    PaymentType: string,
    BrokerageAmount: number, // from SF
    BrokerOriginationFeeSf: number, // from SF
    DocFeeSf: number, // from SF
    RateSf: number, // from SF
    SpecialConditions?: string // from SF,
    PreviousLoan: 'Yes' | 'No',
    LastUpdatedByUser?: {id: number, name: string} | null,
    RepaymentType?: string
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  entityName: string,
  DocumentTypes?: DocumentTag[];
  PreviousBusiness: BusinessNumberSearchValue;
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

export interface TradeFinanceApplication {
  ApplicationType: 'TradeFinance',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  AppInfoSalesforceID?:string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BusinessDescription: string,
    BusinessSell: null,
    BusinessSellTerms: null,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: {lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: null,
  AuthorisedSignatory: null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: SearchCompanyByABNResult,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: IndividualWithAssetsAndLiabilities[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies: null,
  PricingDetails: {
    LimitRequest: number,
    Deposit: number,
    Margin: number;
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[];
  PreviousBusiness: BusinessNumberSearchValue;
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

export interface CorporateLoanApplication {
  ApplicationType: 'CorporateLoans',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  AppInfoSalesforceID?: string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    BrokerflowDocumentID?: string,
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BusinessDescription: string,
    BusinessSell: null,
    BusinessSellTerms: null,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: { lat: string, long: string },
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: null,
  AuthorisedSignatory: null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: SearchCompanyByABNResult,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: IndividualWithAssetsAndLiabilities[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies: null,
  PricingDetails: {
    CreditRateAdjustment?: number,
    Rate: number,
    DocFee: number,
    BrokerageAmount: number,
    Brokerage: number,
    RbaRate: number,
    PreviousLoan: 'Yes' | 'No',
    DirectorScoreRate: 'Yes' | 'No',
    EquifaxScoreAbove600: 'Yes' | 'No',
    AdverseOnFile: 'Yes' | 'No',
    PropertyOwner: 'Yes' | 'No',
    PaymentPeriod: PaymentPeriodType | null,
    LoanAmount: number,
    FacilityEstablishmentFee: number,
    FacilityEstablishmentFeePercent: number,
    LimitRequest: number,
    LoanTerm: number,
    Deposit: number,
    Margin: number;
    ltv: LtvSelectionType,
    securityType: SecurityTypeSelectionType ,

    BankStatementSubmitted: string,
    BrokerOriginationFee: number,
    DocFeeFinanced: string,
    InvoiceAmount: number,
    AmountFinance?: number,  // filled by SF pricing details callback
    Repayment: number,
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    PaymentType: string,
    SpecialConditions?: string,
    LastUpdatedByUser?: {id: number, name: string} | null,
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[];
  PreviousBusiness: BusinessNumberSearchValue;
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

// export interface FloorplanFinanceApplication {
//   ApplicationType: 'FloorplanFinance',
//   Source: ApplicationSource,
//   isDeleted:boolean,
//   GrowRiskRatingData?: RiskRatingInterface | null,
//   BrokerName: string,
//   AppInfoSalesforceID?: string,
//   AppInfoBrokerSalesforceID?: string,
//   AppInfoStageName?: string,
//   BrokerSalesforceId: string,
//   BrokerABN: string,
//   BrokerACN: string,
//   AcceptQuotation: boolean,
//   OppStatus: InteflowStatusRecord,
//   AppInfo: {
//     StageName: string | null,
//     SalesforceId?: string | null,   // OppId in salesforce
//     CustomerId?: string | null,     // AccountId in salesforce
//     ContactId?: string | null,
//     BrokerAppID: string,
//     BrokerSalesforceID: string,
//     BrokerAbn?: string,
//     BrokerEntityName?: string,
//     BusinessDescription: string,
//     BusinessSell: null,
//     BusinessSellTerms: null,
//     BusinessUse?: 'Yes' | 'No',
//     FinanceType: string,
//     IntroducerGroup: string,
//     Notes: string,
//     PrivacyConfirmation?: 'Yes' | 'No',
//     SelfServiceability: string,
//     UserEmail: string,
//     UserFirstName: string,
//     UserLastName: string,
//     MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
//   },
//   AdditionalBrokerCorrespondent: UserSelectionValue,
//   BrokerContact: SalesforceContactSameAccountSelectionValue,
//   ApplicantLocation: { lat: string, long: string },
//   ApplicationId: number,
//   ApplicationNotes: string,
//   AssetSpec: null,
//   AuthorisedSignatory: null,
//   BrokerAppId: string,
//   CommercialEntities: CommercialEntity[],
//   CompanyDetails: SearchCompanyByABNResult,
//   CompanyId: number,
//   CompanyName: string,
//   Contacts: Contact,
//   CreateTime: string,
//   DocumentResult?: DocumentResult[],
//   //Documents?: AppDocument[] | Base64File[],
//   EditLockByUserId: number,
//   Expense: null,
//   IP: string,
//   Individuals: IndividualWithAssetsAndLiabilities[],
//   InteflowStatus: {
//     StageName: string,
//     ec1_CreditReasoning__c: string,
//     ec1_Credit_Approval_DateTime__c: string,
//     ec1_Credit_Approval_Date__c: string,
//   } | null,
//   InteflowStatusStageName: InteflowStatusStageType,
//   IsEditLock: boolean,
//   Policies: null,
//   PricingDetails: {
//     LimitRequest: number,
//     Deposit: number,
//     Margin: number;
//   },
//   // Background?:Background[],
//   QuoteId: null,
//   Reference: Reference[],
//   Status: string,
//   UserId: number,
//   entityName: string,
//   DocumentTypes?: DocumentTag[];
//   PreviousBusiness: BusinessNumberSearchValue;
//   DirectSalesUserFirstName?: string;
//   DirectSalesUserLastName?: string;
//   DirectSalesUserEmail?: string;
// }

export interface ConsumerFinanceApplication {
  ApplicationType: 'Consumer',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  AppInfoSalesforceID?:string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    AssetCategory: string,
    AssetType: string,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: {lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: AssetSpec,
  AuthorisedSignatory: null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: null,
  CompanyId: number,
  CompanyName: string,
  Contacts: null,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense?: {
    CarLoans?: number,
    CreditCardLimit?: number,
    Education?: number,
    Electricity?: number,
    Groceries?: number,
    HomeLoans?: number,
    Insurance?: number,
    OtherLoans?: number,
    OtherUtilities?: number,
    PersonalLoans?: number,
    ShareofExpense?: number,
  },
  IP: string,
  Individuals: ConsumerIndividual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies: null,
  PricingDetails: {
    AdverseOnFile: 'Yes' | 'No',
    AssetCondition: 'New' | 'Used',
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    BrokerOriginationFee: number,
    Brokerage: number,
    Deposit: number,
    DocFee: number,
    DocFeeFinanced: string,
    EquifaxScoreAbove600: 'Yes' | 'No',
    InterestRate: number,
    InvoiceAmount: number,
    AmountFinance: number,
    LoanAmount: number,
    LoanTerm: number,
    PaymentPeriod: PaymentPeriodType | null,
    PrivateSale: PrivateSaleType,
    PropertyOwner: 'Yes' | 'No',
    Repayment: number,
    Margin: number;
    Rate: number,
    PaymentType: string,
    APR: number,
    BrokerageAmount: number, // from SF
    BrokerOriginationFeeSf: number, // from SF
    DocFeeSf: number, // from SF
    RateSf: number, // from SF
    SpecialConditions?: string, // from SF,
    TransactionType?: TransactionType,
    PreviousLoan: 'Yes' | 'No',
    Hybrid: 'Yes' | 'No',
    CreditRateAdjustment?: number,
    RateDiscount?: number,
    OversCommission?: number,
    RepaymentType?: string,
    MonthlyAccountKeepingFee?: number,
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[],
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
};

export interface InsurancePremiumApplication {
  ApplicationType: 'InsurancePremium',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  AppInfoSalesforceID?:string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    BrokerAppID: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    Notes: string,
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: {lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: null,
  AuthorisedSignatory: {
    Email: string,
    GivenName: string,
    MiddleName: string,
    MobileNumber: string,
    Role: string,
    SurName: string,
    Title: string,
  }[],
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: null,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: null,
  Expense: null,
  IP: string,
  Individuals: Individual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: boolean,
  Policies?: {
    expiryDate: string,
    inceptionDate: string,
    insurer: string,
    invoiceNumber: string,
    policyNumber: string,
    premiumAmount: string,
    typeOfCover: string,
  }[],
  PricingDetails: {
    BrokerageAmount: number,
    DocFee: number,
    DocFeeFinanced: string,
    LoanTerm: number,
    PaymentPeriod: PaymentPeriodType | null,
    Margin: number;
    Rate: number,
    Repayment: string,
    TotalAmount: number,
    Deposit: number,
    DocFeeSf: number, // from SF
    RateSf: number, // from SF
    SpecialConditions?: string // from SF,
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  UserId: number,
  entityName: string,
  DocumentTypes?: DocumentTag[];
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}

export interface BusinessOverdraftApplication {
  ApplicationType: 'BusinessOverdraft',
  Source: ApplicationSource,
  isDeleted:boolean,
  GrowRiskRatingData?: RiskRatingInterface | null,
  BrokerName: string,
  UserId: number,
  AppInfoSalesforceID?:string,
  AppInfoBrokerSalesforceID?: string,
  AppInfoStageName?: StageNameType | null,
  BrokerSalesforceId: string,
  BrokerABN: string,
  BrokerACN: string,
  AcceptQuotation: boolean,
  OppStatus: InteflowStatusRecord,
  LvrCalculatorValue: LvrCalculatorValue,
  DscrCalculatorValue: DscrCalculatorValue,
  AppInfo: {
    StageName: StageNameType | null,
    SalesforceId?: string | null,   // OppId in salesforce
    CustomerId?: string | null,     // AccountId in salesforce
    ContactId?: string | null,
    AssetCategory: string,
    AssetType: string,
    BrokerAppID: string,
    BrokerflowDocumentID?: string,
    BrokerSalesforceID: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    BusinessUse?: 'Yes' | 'No',
    FinanceType: string,
    IntroducerGroup: string,
    LoanPurpose: string,
    Notes: string,
    PrivacyConfirmation?: 'Yes' | 'No',
    SelfServiceability: string,
    UserEmail: string,
    UserFirstName: string,
    UserLastName: string,
    MetApplicant?: NotNullable<CustomerApplicantTypeValue>['type'],
  },
  AdditionalBrokerCorrespondent: UserSelectionValue,
  BrokerContact: SalesforceContactSameAccountSelectionValue,
  ApplicantLocation: {lat: string, long: string},
  ApplicationId: number,
  ApplicationNotes: string,
  AssetSpec: null,
  AuthorisedSignatory: null,
  BrokerAppId: string,
  CommercialEntities: CommercialEntity[],
  CompanyDetails: SearchCompanyByABNResult,
  CompanyId: number,
  CompanyName: string,
  Contacts: Contact,
  CreateTime: string,
  DocumentResult?: DocumentResult[],
  //Documents?: AppDocument[] | Base64File[],
  EditLockByUserId: number,
  Expense: null,
  IP: string,
  Individuals: Individual[],
  InteflowStatus: {
    StageName: StageNameType,
    ec1_CreditReasoning__c: string,
    ec1_Credit_Approval_DateTime__c: string,
    ec1_Credit_Approval_Date__c: string,
  } | null,
  InteflowStatusStageName: InteflowStatusStageType,
  IsEditLock: false,
  Policies: null,
  PricingDetails: {
    FacilityEstablishmentFee: number,
    FacilityEstablishmentFeePercent: number,
    AdverseOnFile: 'Yes' | 'No',
    BankStatementSubmitted: string,
    BrokerOriginationFee: number,
    Brokerage: number,
    DocFee: number,
    DocFeeFinanced: string,
    EquifaxScoreAbove600: 'Yes' | 'No',
    InvoiceAmount: number,
    LoanAmount: number,
    AmountFinance?: number,  // filled by SF pricing details callback
    LoanTerm: number,
    RbaRate: number,
    PaymentPeriod: PaymentPeriodType | null,
    PropertyOwner: 'Yes' | 'No',
    Rate: number,
    CreditRateAdjustment?: number,
    Repayment: number,
    BalloonPayment: number,
    BalloonPaymentPercentage: number,
    DirectorScoreRate: 'Yes' | 'No',
    PaymentType: string,
    BrokerageAmount: number,
    BrokerOriginationFeeSf: number, // from SF
    DocFeeSf: number, // from SF
    RateSf: number, // from SF
    FacilityEstablishmentFeeSf: number, // from SF
    FacilityEstablishmentFeePercentSf: number, // from SF
    SpecialConditions?: string // from SF,
    PreviousLoan: 'Yes' | 'No',
    Margin: number,
    LastUpdatedByUser?: {id: number, name: string} | null,
  },
  QuoteId: null,
  Reference: Reference[],
  Status: string,
  entityName: string,
  DocumentTypes?: DocumentTag[],
  PreviousBusiness: BusinessNumberSearchValue;
  DirectSalesUserFirstName?: string;
  DirectSalesUserLastName?: string;
  DirectSalesUserEmail?: string;
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
}


export type InteflowStatusStageType = null | 'Application Received' | 'Closed Lost' | 'Closed Won' | 'Credit Approved' | 'Deal Preparation' |
  'Docs Out' | 'Documentation' | 'Lead' | 'More Information' | 'Proposal' | 'Prospect' | 'QA' | 'Settlement' | 'Settlement Pending' |
  'Submitted to Credit' | 'Waiting for Bank Statements';

export type Application = AssetFinanceApplication | InsurancePremiumApplication | BusinessLoansApplication | ConsumerFinanceApplication
 | TradeFinanceApplication | CorporateLoanApplication | CommercialFinanceApplication | BusinessOverdraftApplication;

// NOTE: we should not do this, applicationBackground is not FloorplanFinanceApplication
// export type applicationBackground = FloorplanFinanceApplication
export interface ApplicationsReport {
  underReview: number,
  settlement: number,
  won: number,
};

export type ApplicationSort = { prop: 'AppInfoStageName'| 'BrokerName' | 'BrokerAppId' | 'Status' | 'CreateTime' | 'CompanyName', dir: 'ASC' | 'DESC'}[]
export interface ApplicationTypeFilter {
  type: AllApplicationTypes,
  name: string;
}

export type ApplicationState = 'all' | 'under-review' | 'in-settlement' | 'closed-won';

export type SearchCompanyResult = SearchCompanyByNameResult | SearchCompanyByABNResult;

export type SearchCompanyByNameResult = {
  numberOfRecords: number;
  records: { organisationName: string, abn: number, status: 'Active' | 'Cancelled' }[]
  ABRPayloadSearchResults?: {
    response?: {
      exception?: {
        exceptionCode: string,
        exceptionDescription: string,
      }
    }
  },
};

export type SearchCompanyByABNResult = {
  ABN: {
    identifierValue: number,
    isCurrentIndicator: string,
    replacedFrom: string,
  }[] | {
    identifierValue: number,
    isCurrentIndicator: string,
    replacedFrom: string,
  },
  ABNAgeMonths: number,
  GSTAgeMonths: number,
  ASICNumber: number,
  entityStatus: {
    effectiveFrom: string, effectiveTo: string, entityStatusCode: 'Active' | 'Cancelled'
  }[] | {effectiveFrom: string, effectiveTo: string, entityStatusCode: 'Active' | 'Cancelled'},
  entityType: { entityDescription: string, entityTypeCode: string },
  goodsAndServicesTax: {
    effectiveFrom: string,
    efectiveTo: string,
  }[] | {effectiveFrom: string, effectiveTo: string},
  legalName: (
    {effectiveFrom: string, effectiveTo: string, familyName: string, givenName: string, otherGivenName: string}[] |
    {effectiveFrom: string, effectiveTo: string, familyName: string, givenName: string, otherGivenName: string})
  ,
  mainBusinessPhysicalAddress: {
    effectiveFrom: string,
    effectiveTo: string,
    postcode: number,
    stateCode: string,
  }[],
  mainTradingName: {
    organisationName: string,
    effectiveFrom: string,
    effectiveTo: string,
    orgnisationName: string,
  } | {
    organisationName: string,
    effectiveFrom: string,
    effectiveTo: string,
    orgnisationName: string,
  }[],
  organisationName: string,
  recordLastUpdatedDate: string,
  records: {organisationName: string, abn: number}[],
  ABRPayloadSearchResults?: {
    response?: {
      exception?: {
        exceptionCode: string,
        exceptionDescription: string,
      }
    }
  },
};


export type GeoLocation = {
  lat?: string, long?: string,
}


export interface BrokerOfUser {
  // AggregatorId: string,
  EntityName: string,
  SalesforceId: string,
  Relationship: 'disclosed' | 'undisclosed',
  ABN: string,
  ACN?: string,
}

// export interface ApplicationId {
//   ApplicationId: number
// }

export interface SaveApplicationResult {
  ApplicationId: number;
  BrokerApplicationId: string;
}

export interface SubmitApplicationResult {
  CustomerId: string;     // salesforce Accountid
  ContactId: string;      // salesforce Contactid
  SalesforceId: string;   // salesforce Oppid
  StageName: string;      // salesforce StageName
  Status: string;         // salesforce Decision
}

export type ApplicationSource = 'portal' | 'external' | 'direct';

export type SaveAndCreateApplicationData = SaveApplicationData & {ABN: string};

export interface SyncOpportunityToSFData {
  Deposit_paid_to__c: 'Dynamoney' | 'Supplier'
  Deposit_to_Dyna__c: number,
  Deposit_to_Supplier__c: number,
  Broker_Origination_Fee__c: number,
  Invoice_Price__c: number,
  Amount: number,
  Amount_Financed__c: number,
  Deposit_where_applicable__c: number,
  Doc_Fee_Financed__c: boolean,
  APR__c: number,
  Rate__c: number,
  Margin__c: number,
  Asset_Category__c: string, // 130, 137, 132, 133, 134, 135, 136, 106, Other, 139, 138, 140, 141, 142,
  Asset_Type__c: string, // eg. 130-1
  Make__c: string,
  Asset_Year__c: string,
  Goods_Description__c: string, // make family year model
  Private_Sale__c: PrivateSaleType, // 'Yes', 'No', 'Sales and Lease Back', 'Capital Raise', 'Refinance', 'Balloon Refinance',
  Property_Ownership__c: 'Yes' | 'No', // 'Yes', 'No'
  Adverse__c: 'Yes' | 'No', // 'Yes', 'No'
  ec1_Term__c: number,  // number of months
  FacilityEstablishmentFee__c: number, // amount
  Facility_Establishment_Fee__c: number, // percent
  Brokerage__c: number,
  Brokerage_Percentage__c: number,
  New_or_Used__c: 'New' | 'Used' | 'NA'  // 'New', 'Used', NA
  Private_Sales__c: string, // 'Yes', 'No', 'Sales and Lease Back', 'Capital Raise', 'Refinance', 'Balloon Refinance'
  Repayment_Frequency__c: string, // 'Monthly', 'Weekly', 'Quarterly', 'Fortnightly'
  ProductType__c: string, // 'Business Loan', 'Chattel Mortgage', 'Finance Lease', 'Insurance Premium', 'Invoice Finance', 'Operating Lease', 'Rental', 'Software Agreement', 'Trade Finance', 'Asset Finance', 'Business Overdraft', 'Corporate Loan', 'Floorplan Finance'
  Documentation_Fee__c: number, // currency
  ec1_ResidualValue__c: number, // percent
  Residual_Value__c: number, // currency
  Overs_Commission__c: number,
}

export interface SaveApplicationPricingDetailsData {
  LoanTerm?: number,
  InvoiceAmount?: number,
  Deposit?: number,
  Brokerage?: number,
  LoanAmount?: number,
  PaymentPeriod?: string,
  DocFee?: number,
  DocFeeFinanced?: 'Yes' | 'No',
  BrokerOriginationFee?: number,
  PropertyOwner?: 'Yes' | 'No',
  AdverseOnFile?: 'Yes' | 'No',
  EquifaxScoreAbove600?: 'Yes' | 'No',
  Rate?: number,
  BalloonPaymentPercentage?: number,
  BalloonPayment?: number,
  Repayment?: number,
  BankStatementSubmitted?: 'Yes' | 'No',
  PrivateSale?: PrivateSaleType,
  AssetCondition?: string,
  TransactionType?: TransactionType,
  PreviousLoan?: 'Yes' | 'No',
  LimitRequest?: number,
  AmountFinance?: number,
  InterestRate?: number,
  APR?: number,
  DirectorScoreRate?: 'Yes' | 'No',
  RbaRate?: number,
  BrokerageDollar?: number,
  FacilityEstablishmentFeePercent?: number,
  FacilityEstablishmentFee?: number,
  Margin?: number,
  BrokerageAmount?: number,
  CreditRateAdjustment?: number,
  LastUpdatedByUser?: {id: number, name: string} | null,
  Hybrid?: 'Yes' | 'No',
  ltv?: LtvSelectionType | null,
  securityType?: SecurityTypeSelectionType | null,
  RateDiscount?: number | null,
  OversCommission?: number | null,
  SaleRentBack?: string | null,
  ResidualValue?: number | null,
  MonthlyFacilityFeePercentage?: number | null,
  RepaymentType?: string | null,
  MonthlyAccountKeepingFee?: number | null,
}
export interface SaveApplicationData {
  ApplicationType: ApplicationTypes;
  IP: string,
  LvrCalculatorValue?: LvrCalculatorValue,
  DscrCalculatorValue?: DscrCalculatorValue,
  Source?: ApplicationSource,
  ApplicantLocation: GeoLocation,
  UserId: number,
  AcceptQuotation?: boolean,
  ApplicationNotes?: string,
  CompanyDetails?: SearchCompanyByABNResult,
  AdditionalBrokerCorrespondent?: UserSelectionValue,
  BrokerContact?: SalesforceContactSameAccountSelectionValue,
  CompanyId?: number,
  Status?: string,
  DocumentTypes?: DocumentTag[],
  OppStatus?: InteflowStatusRecord,
  PreviousBusiness?: BusinessNumberSearchValue,
  AppInfo: {
    BrokerAppID?: string,
    IntroducerGroup?: string,
    Disclosed?: boolean,
    FinanceType?: string,
    UserId?: number,
    CompanyId?: number,
    UserEmail?: string,
    UserFirstName?: string,
    UserLastName?: string,
    Notes?: string,
    BusinessUse?: 'Yes' | 'No',
    SelfServiceablilty?: 'Yes' | 'No',
    PrivacyConfirmation?: 'Yes' | 'No',
    BusinessSell?: string | null,
    BusinessSellTerms?: string | null,
    BrokerflowDocumentID?: string,
    BrokerSalesforceID?: string,
    BrokerAbn?: string,
    BrokerEntityName?: string,
    AssetCategory?: string,
    ContactId?: string | null,
    CustomerId?: string | null,
    StageName?: string | null,
    SalesforceId?: string | null,
    MetApplicant?: string,
    isSwitchToEasyDoc?: boolean,
    productType?: AssetFinanceProductType,
    ApplicationType?: string,
    OwnerId?: string,
  }
  AssetSpec?: {
    make?: string,
    family?: string,
    year?: string,
    vehicle?: string,
    description?: string,
    LVR?: number,
    [data: string]: any,
  },
  CommercialEntities?: {
    LegalName?: string,
    EntityType?: 'P/L' | 'PTNR' | 'PTY' | 'SLTR' | 'TRST' | null,
    ABN?: string,
    ACN?: string,
    TradingName?: string,
    Type?: string,
    PrimaryIndustry?: string,
    IndustrySector?: string,
    Revenue?: number,
    OperateatCommercialAddressFlag?: boolean,
    PhoneNumber?: string,
    AreaCode?: string,
    BusinessCategory?: string,
    PrinciplePlaceofBusiness?: {
      UnformattedAddress: string, 
      Suburb?: string, 
      State?: string, 
      Postcode?: string, 
      Property?: string,
      UnitNumber?: string,
      StreetNumber?: string,
      StreetName?: string,
      StreetType?: string,
    },
    YearsInBusiness?: string,
    Website?: string,
  }[],
  Policies?: {
    typeOfCover: string,
    policyNumber: string,
    insurer: string,
    inceptionDate: string,
    expiryDate: string,
    premiumAmount: number,
    invoiceNumber: string,
  }[],
  Individuals: {
    id?: string,
    sfContactId?: string,
    thirdPartyEntity?: ThirdPartyEntity,
    Title?: string,
    GivenName?: string,
    MiddleName?: string,
    SurName?: string,
    DoB?: string,
    Gender?: 'M' | 'F',
    PrivacyConsent?: boolean,
    PropertyOwnerFlag?: boolean,
    PropertyValue?: number,
    MortgageBalance?: number,
    AddressofPropertyOwned?: Omit<Address, 'IsManualInput'>,  // {UnformattedAddress?: string},
    PreviousAddress?: Omit<Address, 'IsManualInput'>, // {UnformattedAddress: string},
    TimeatAddress?: number,
    GuarantorFlag?: boolean,
    Email?: string,
    MobileNumber?: string,
    HomeAddress?: Omit<Address, 'IsManualInput'>, // {UnformattedAddress?: string},
    Role: RoleTypes,
    SignerRole?: SignerRoleTypes,
    DriverLicenseExpiryDt?: string,
    DriverLicenseState?: string,
    AssetsTotal?: number,
    Assets?: {
      AssetType: string,
      Value: number,
      Address?: Omit<Address, 'IsManualInput'>, // {UnformattedAddress: string},
      Description?: string,
    }[],
    LiabilitiesTotal?: number,
    Liabilities?: {
      Type: string,
      Value: number,
      Address?: Omit<Address, 'IsManualInput'>, // {UnformattedAddress: string},
      Financier?: string,
    }[],
    NetAsset?: number
  }[],
  Reference?: {
    CompanyName?: string,
    Telephone?: string,
    ContactPerson?: string,
    Type?: string,
  }[],
  // Background?:Background[],
  PricingDetails: SaveApplicationPricingDetailsData,
  Contacts?: {
    Title?: string,
    GivenName?: string,
    SurName?: string,
    Email?: string,
    MiddleName?: string,
    Telephone?: string,
    Mobile?: string,
    AreaCode?: string,
  },
  AuthorisedSignatory?: {
    Role: string,
    Title: string,
    GivenName: string,
    SurName: string,
    MiddleName: string,
    Email: string,
    MobileNumber: string,
  }[],
  Expense?: {
    HomeLoans: number,
    CarLoans: number,
    PersonalLoans: number,
    OtherLoans: number,
    Electricity: number,
    OtherUtilities: number,
    Education: number,
    Groceries: number,
    Insurance: number,
    ShareofExpense: number,
  },
  isDeleted?: boolean,
  DirectSalesUserFirstName?: string,
  DirectSalesUserLastName?: string,
  DirectSalesUserEmail?: string,
  SecurityPin?: string,
  SystemCreditStatus?: string,
  CreditOfficerStatus?: string,
  CreditOfficerStatusUpdatedBy?: number,
  SubmitThroughNewCreditFlow?: boolean,
}

export interface riskRating {
  growRiskRating: number,
  abnAge: number,
  gstAge: number,
  adverse: string,
  propertyOwnership: string,
  exceptionToPolicy: string,
  companyEquifaxScore: number,
  lowestIndividualEquifaxScore: number,
  highestIndividualEquifaxScore: number,
  lossReasonCategory: string,
  lossReasonDetails: string,
  creditDecisionFlag: string,
  closedLostDatetime: string,
  creditorWatchScore: number
}

export interface UpdateApplicationData extends SaveApplicationData {
  Documents?: {
    new? : Base64File[],
    remove?: {
      // base64?: string,
      name: string,
    }[],
  },
  UpdateApplicationIgnoreStatus?: boolean,
}

export type FetchABRdataByABNResult =  {
  acn?: string,
  abn?: string,
  entityStatus?: string,
  ABNAgeMonths?: number,
  yearsInBusiness?: number,
  GSTAgeMonths?: number,
  entityType?: 'PTY' | 'TRST' | 'PTNR' | 'PTY' | 'SLTR',
  organisationType?: string,
  legalName?: string;
};

export interface ApplicationStatistics {
  last30days: number;
  prevLast30days: number;
  prevMonth: number;
  prevMonthText: string;
  thisMonth: number;
  thisMonthText: string;
  totalApplications: number;
}

export interface OverdraftProductLast12MonthChartStatistic{
  year:number,
  month:number
  month_name:string,
  value:number
}
export interface OverdraftProductApplicationStatistics {
  totalBalance : number;
  totalLimit : number;
  totalActiveAccounts : number;
  totalActiveCards : number;
  totalBalanceArrears : number;
  balanceArrearsSum:number;
  numberAccountsArrears : number;
  get30DaysCardTransactionVolume:number,
  get30DayBankTransactionVolume:number,
  get60DaysCardTransactionVolume:number,
  get60DayBankTransactionVolume:number,
  get90DaysCardTransactionVolume:number,
  get90DayBankTransactionVolume:number,
  totalSpendLast30Days:number,
  totalSpendPrior30Days:number,
  totalSpend90Days:number,
  last30DaysNewAccountCount:number,
  prior30DaysNewAccountCount:number,
  currentMonthNewAccountCount:number,
  lastMonthNewAccountCount:number,
  last30DaysCardActiveCount:number,
  prior30DaysCardActiveCount:number,
  currentMonthCardActiveCount:number,
  lastMonthCardActiveCount:number,
  last12MonthNewAccountActiveCount:OverdraftProductLast12MonthChartStatistic [],
  last12MonthTotalLimit:OverdraftProductLast12MonthChartStatistic [],
  last12MonthSpentAmount:OverdraftProductLast12MonthChartStatistic [],
  last12MonthTotalBalance:OverdraftProductLast12MonthChartStatistic []
}

export interface ApplicationMonthlyStatistics {
  app_volume: { monthNumber: number, yearNumber: number, counts: number }[];
  monthly_settlement: { monthNumber: number, yearNumber: number, counts: number }[];
}

export interface Article {
  title: string;
  description: string;
  content: string;
  url: string;
  image: string;
  publishedAt: string;
  source: { url: string; name: string; };
}

export interface GetNewsResult {
  articles: Article[];
  status: string;
  totalArticles: number;
}

export interface InProgressApplicationStatistics {
  approved_apps: number;
  total_inprogress: number;
  underreview_apps: number;
}

export interface SettledApplicationStatistics {
  approved_apps: number;
  approved_last30days: number;
  approved_lastMonth: number;
  approved_lastprev30days: number;
  approved_thisMonth: number;
  prevMonthText: string;
  settled_apps: number;
  settled_last30days: number;
  settled_lastMonth: number;
  settled_lastprev30days: number;
  settled_thisMonth: number;
  submitted_apps: number;
  submitted_last30days: number;
  submitted_lastMonth: number;
  submitted_lastprev30days: number;
  submitted_thisMonth: number;
  thisMonthText: string;
  total_applications: number;
}

export interface CompanyDocumentData {
  legalname: string;
  abn: string;
  latestApplicationId: number;
  docs: AzureStorageDocument[];
}

export interface ConsumerDocumentData {
  consumername: string;
  dob: string;
  latestApplicationId: number;
  docs: AzureStorageDocument[];
}

export interface ApplicationDocumentsData {
  companyDocs: CompanyDocumentData[];
  consumerDocs: ConsumerDocumentData[];
}

export interface ApplicationDocumentsTableData {
  type: 'company' | 'consumer';
  companyLegalName: string;
  companyAbn: string;
  consumerName: string;
  consumerDob: string;
  latestApplicationId: number;
  docs: AzureStorageDocument[];
}

export interface DocumentCognitiveSearchResult {
  legalname: string | null;
  abn: string | null;
  consumername: string | null;
  dob: string | null;
  name: string | null;
  path: string | null;
  isdeleted: string | null;
}


export interface LastRecentApplicationsBundle {
  all: {
    total: number,
    records: SimplifiedApplication[],
  },
  underReview: {
    total: number,
    records: SimplifiedApplication[],
  },
  inSettlement: {
    total: number,
    records: SimplifiedApplication[],
  },
  closedWon: {
    total: number,
    records: SimplifiedApplication[],
  },
}

export interface SyncPricingDetailsBody extends InteflowStatusRecord {
  Id: string, // SalesforceId
  RecordTypeId: string,
  Record_Type_Name_Text__c: 'Accreditation' | 'Asset Finance' | 'Business Loan' | 'Business Overdraft' | 'Consumer Asset Finance' | 'Corporate Loan' | 'Insurance Premium' | 'Invoice Finance' | 'Trade Finance' ,
  FacilityEstablishmentFee__c?: number,
  Facility_Establishment_Fee__c?: number,
  Residual_Value__c?: number,
  ec1_ResidualValue__c?: number,
  Broker_Origination_Fee__c?: number,
  Brokerage_ex_Origination__c?: number,
  Deposit_where_applicable__c?: number,
  Documentation_Fee__c?: number,
  Amount?: number,
  Invoice_Price__c?: number,
  Amount_Financed__c?: number,
  Loan_Amount__c?: number,
  ec1_Term__c?: number,
  Repayment_Frequency__c?: number,
  Rate__c?: number,
  Calculated_Repayment__c?: number,
  Calculated_Doc_Fee__c?: number,
  Doc_Fee_Financed__c?: string,
  Payment_Type__c?: string,
  Private_Sale__c?: string,
  Name?: string,
  ACN_Number__c?: string,
  ABN_Number__c?: string,
  Address__c?: string,
  StageName?: string,
  Special_Conditions__c?: string;
  Car_Loans__c?: number,
  Total_Credit_Card_Limits__c?: number;
  Education__c?: number;
  Electricity__c?: number;
  Groceries__c?: number;
  Home_Loans__c?: number;
  Insurance__c?: number;
  Other_Loans__c?: number;
  Other_Uttilities__c?: number;
  Personal_Loans__c?: number;
  Share_of_Monthly_Living_Expenses__c?: number;
  Repayment__c?: number;
  Additional_Security__c?: string;
  Goods_Description__c?: string;
  New_or_Used__c?: string;
  Margin__c?: number;
  Credit_Officer__c?: string;
}

export interface CopyApplicationDialogData {
  application: Application;
}

export interface CopyApplicationDialogResult {
  readyForSubmission: boolean;
  applicationType: ApplicationTypes | null;
}

export interface CopyApplicationBody {
  application: Application;
  newApplicationType: ApplicationTypes;
  user: User;
  ip: string;
  geoLocation: GeoLocation;
}

export type CopyApplicationFn = (body: CopyApplicationBody) => Observable<SaveApplicationResult>

export interface ApplicationOwner {
  Id: string; // sf id
  Name: string;
  CompanyName: string | null;
  UserName: string;
  Email: string;
  MobilePhone: string | null;
  Address: string | null;
}

export interface ApplicationCreditOfficer {
  CreditOfficerEmail: string;
  Id : string
}

export type GetApplicationOwnerFn = (salesforceId: string) => Observable<ApplicationOwner | null>;
export type GetApplicationCreditOfficerFn = (salesforceId: string) => Observable<ApplicationCreditOfficer | null>;
export type GetGeoLocationFn = () => Promise<GeoLocation>;

export type RefreshBankStatementFn = (abn: string) => Observable<PayloadApiResponse<Response | {Error: string}>>;

export type TaxInvoiceDetails = TaxInvoiceDetailsSuccess | TaxInvoiceDetailsError;
export interface TaxInvoiceDetailsSuccess {
  InvoiceId: string;
  InvoiceDate: string;
  AccountName: string;
  AccountNumber: string;
  Bsb: string;
  FinancialInstitution: string;
  Amount: string;
}
export interface TaxInvoiceDetailsError { Error: string }

export type ExtractTaxInvoiceFn = (file: File) => Promise<Observable<TaxInvoiceDetails>>;

export interface TaxInvoiceDetailsDialogData {
  taxInvoiceDetails: TaxInvoiceDetailsSuccess
}

export interface TaxInvoiceDetailsDialogResult {
  readyForSubmission: boolean;
  invoiceDetails: {
    invoiceId: string;
    invoiceDate: string;
    accountName: string;
    bsb: BsbComponentValue;
    accountNumber: string;
    financialInstitution: string;
    amount: CurrencyInputValue;
  }
}

export type UpdateApplicationFn = (ApplicationId: number, data: UpdateApplicationData) => Observable<ApiResponse>;

export type UpdateApplicationSfFn = (ApplicationId: number, data: UpdateApplicationData) => Observable<ApiResponse>;

export type GetOriginatorBusinessByIdFn = (originatorBusinessId: number) => Observable<OriginatorBusiness | null>;

export interface ManageApplicationDialogData {

}

export interface ManageApplicationDialogResult {
  firstName: string,
  lastName: string,
  email: string,
}

export interface DirectSaleNotificationBody {
  firstName: string,
  lastName: string,
  email: string,
  applicationId: number,
  applicationType: ApplicationTypes,
  businessName: string,
}

export interface PrivacyConsent {
  id: number,
  type: 'application',
  individualId: string,
  status: PrivacyConsentStatus,
  applicationId: number,
  userId: number,
  createdTime: string,
  lastUpdatedTime: string,
  ip: string,
  FamilyName?: string,
  GivenName?: string,
}

export interface SendPrivacyConsentEmailBody {
  individual: IndividualWithResult;
  applicationId: number;
  applicationName: string;
}

export interface UpdatePrivacyConsentStatus {
  id: number;
  status: PrivacyConsentStatus;
  ip: string;
}

export type SendPrivacyConsentEmailFn = (individual: IndividualWithResult, applicationId: number, applicationName: string) => Observable<ApiResponse>;

export type PrivacyConsentStatus = 'created' | 'opened' | 'accepted';

export interface AdminPrivacyConsent {
  id: number,
  type: 'admin',
  reference: string;
  customerName: string;
  customerEmail: string;
  dynamoneyUserEmail: string;
  userId: number;
  status: PrivacyConsentStatus,
  createdTime: string,
  lastUpdatedTime: string,
  ip: string,
  uuid: string,
}

export interface SendAdminPrivacyConsentEmailBody {
  reference: string;
  customerName: string;
  customerEmail: string;
  dynamoneyUserEmail: string;
}

export type SendAdminPrivacyConsentEmailFn = (
  reference: string,
  customerName: string,
  customerEmail: string,
  dynamoneyUserEmail: string,
) => Observable<ApiResponse>;


// NOTE: used by SalesforceContactSameAccountSelectionValue (in contact-same-company-selection-component.domain.ts)
export interface BrokerContactType {
  FirstName: string,
  LastName: string,
  AccountId: string, // salesforce account id
  Email: string,
  MobilePhone: string,
  Id: string, // salesforce contact id
}

export interface EditInvoiceAmountDialogData {
  title: string;
  invoiceAmount: number;
  depositAmount: number;
  min: number;
  //max: number | null;
  minDeposit: number | null;
  maxDeposit: number | null;
  originalLoanAmount: number;
  limitMaxInvoiceAmount: boolean;
  financeType: FinanceType;
  isPropetyOwner: boolean;
  isConsumer: boolean;
  assetCategoryIndex: string;
  productType?: AssetFinanceProductType;
}

export interface EditInvoiceAmountDialogResult {
  readyForSubmission: boolean;
  invoiceAmount: number;
  depositAmount: number;
}

export interface BankStatementsUploadObject {
  applicationId: number
}

export interface SfContact {
  userId: number,
  sfContactId?: string,
  sfAccountId: string,
  firstname: string,
  lastname: string,
  middlename: string,
  email: string,
  mobile: string,
}

export interface SFOpportunityData {
  AccountId?: string;
  BrokerContact__c?: string;
  MonthlyFacilityFeePercentage__c?: number;
  Product__c?: string;
  Payment_Type__c?: string;
  isSwitchToEasyDoc__c?: boolean;
  RecordTypeId?: string;
  Sale_And_Rent_Back__c?: string;
  Private_Sale__c?: string;
  FacilityEstablishmentFee__c?: number;
  Facility_Establishment_Fee__c?: number;
  Brokerage__c?: number;
  Asset_Year__c?: string;
  ProductType__c?: string;
  Overs_Commission__c?: number;
  New_or_Used__c?: string;
  ec1_ResidualValue__c?: number;
  Residual_Value__c?: number;
  Goods_Description__c?: string;
  LVR__c?: number;
  Asset_Category__c?: string;
  Asset_Type__c?: string;
  Name?: string;
  CloseDate?: string;
  StageName?: string;
  Amount?: number;
  ec1_Term__c?: number;
  Repayment_Frequency__c?: string;
  PropertyOwnership__c?: string;
  Inteflow_Reference_Id__c?: string;
  Doc_Fee_Financed__c?: boolean;
  Brokerage_Percentage__c?: number;
  Broker_Origination_Fee__c?: number;
  Documentation_Fee__c?: number;
  Deposit_where_applicable__c?: number;
  ec1_Introducer__c?: string;
  Portal_User_Email__c?: string;
  Portal_User_First_Name__c?: string;
  Portal_User_Last_Name__c?: string;
  Rate__c?: number;
  Margin__c?: number;
  Notes__c?: string;
  Type?: string;
  OwnerId?: string;
  APR__c?: number;
  Broker_Contact_Add__c?: string;
  Broker_Email_Add__c?: string;
  PortalLink__c?: string;
}

export interface SFAccountData {
  RecordTypeId: string,
  ec1_ABN__c: string,
  ec1_ACN__c: string,
  AnnualRevenue: number,
  ec1_ANZSICCode__c: string,
  ec1_EntityType__c: string,
  ec1_Industry__c: string,
  Interflow_Entity_Type__c: string,
  ec1_TradingName__c: string,
  Website: string,
  Type: string,
  Name: string,
  ec1_Email__c: string,
  BillingStreet: string,
  BillingCity: string,
  BillingState: string,
  BillingPostalCode: string,
  Phone: string,
  Business_Loans__c?: boolean,
  Trade_Finance__c?: boolean,
  Insurance_Premium_Finance__c?: boolean,
  Asset_Finance__c?: boolean,
  OwnerId?: string,
  PrimaryContact__c?: string;
}

export interface SFContactArrayData {
  RecordTypeId: string,
  FirstName: string[],
  LastName: string[],
  Salutation: string[],
  Email: string[],
  MobilePhone: string[],
  Phone: string[],
  ec1_DateofBirth__c: string[],
  MailingStreet: string[],
  MailingCity: string[],
  MailingState: string[],
  MailingPostalCode: string[],
  ec1_Guarantor__c: boolean[],
  Not_a_contract_signer__c: boolean[],
}

export interface SFContactData {
  AccountId: string,
  RecordTypeId: string,
  FirstName: string,
  LastName: string,
  Salutation: string,
  Email: string,
  MobilePhone: string,
  Phone: string,
  ec1_DateofBirth__c: string,
  MailingStreet: string,
  MailingCity: string,
  MailingState: string,
  MailingPostalCode: string,
  ec1_Guarantor__c: boolean,
  Not_a_contract_signer__c: boolean,
}
export interface QuotationNotificationDialogData {

}

export interface QuotationNotificationDialogResult {
  readyForSubmission: boolean;
  emailList: string[];
}

export interface ConsumerQuotationTableData {
  id?: number;
  type: 'download' | 'email';
  quotation: string;
  fileId: string;
  emailList: string;
  userId: number;
  createdTime?: string;
}

export interface ConsumerQuotationData {
  monthlyPayment: number;
  monthlyInstallment: number;
  monthlyAccountKeepingFee: number;
  interestRate: number;
  comparisonRate: number;
  amountFinanced: number;
  totalInterest: number;
  totalPayment: number;
  invoiceAmount: number;
  deposit: number;
  residual: number;
  docFee: number;
  loanTerms: number;
  paymentFrequency: RepaymentFrequencyType;
}

export interface ConsumerQuotationTemplateData {
  monthlyPayment: string;
  monthlyInstallment: string;
  monthlyAccountKeepingFee: string;
  interestRate: string;
  comparisonRate: string;
  amountFinanced: string;
  totalInterest: string;
  totalPayment: string;
  invoiceAmount: string;
  deposit: string;
  residual: string;
  docFee: string;
  loanTerms: string;
  paymentFrequency: RepaymentFrequencyType;
}

export interface ConsumerQuotationTemplateDocData extends ConsumerQuotationTemplateData {
  generatedTime: string;
  ip: string;
  consumerFirstName: string;
  consumerLastName: string;
  fileId: string;
}

export interface SendConsumerQuotationData {
  data: ConsumerQuotationData;
  emailList: string[];
  consumerFirstName: string;
  consumerLastName: string;
}

export interface DownloadConsumerQuotationData {
  data: ConsumerQuotationData;
  ip: string;
  generatedTime: string;
  consumerFirstName: string;
  consumerLastName: string;
}

export interface WithdrawApplicationBody {
  salesforceId: string;
  applicationId: number;
  reason: string;
  comment: string;
}

export interface WithdrawApplicationDialogData {

}

export interface WithdrawApplicationDialogResult {
  readyForSubmission: boolean;
  reason: string;
  comment: string;
}

export const withdrawApplicationReasonOptions: string[] = [
  'Approval Time-Lapse',
  'Bankstatements request',
  'Credit SLA',
  'LTV Requirements',
  'Price Loading',
  'Pricing - Lost to Bank',
  'Pricing - Lost to Non-Bank',
  'Request For Information',
  'Valuation',
  'Lost contact with customer',
  'Error by Grow'
]

export type WithdrawApplicationFn = (body: WithdrawApplicationBody) => Observable<ApiResponse>;