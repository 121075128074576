import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AssetFinanceApplicationDetailsComponent} from './asset/asset-finance-application-details.component';
import {BusinessFinanceApplicationDetailsComponent} from './business/business-finance-application-details.component';
import {InsurancePremiumApplicationDetailsComponent} from './insurance/insurance-premium-application-details.component';
import {ConsumerFinanceApplicationDetailsComponent} from './consumer/consumer-finance-application-details.component';
import { CorporateLoansApplicationDetailsComponent } from './corporate-loans/corporate-loans-application-details.component';
import {ApplicationSummaryModule} from '../application-summary-component/application-summary.module';
import {BreadcrumbModule} from '../breadcrumb-component/breadcrumb.module';
import {AssetAppComponent} from './asset/asset-app.component';
import {AssetQuoteComponent} from './asset/asset-quote.component';
import {ChartModule} from '../chart-component/chart.module';
import {PrimaryContactDetailsComponent} from './primary-contact-details.component';
import {ReferencesDetailsComponent} from './references-details.component';
import { CorporateLoansBackgroundDetailsComponent } from './corporate-loans/corporate-loans-background-details.component';
import {IndividualDetailsComponent} from './individual-details.component';
import {MessageBoxModule} from '../message-box/message-box.module';
import {DocumentDetailsComponent} from './document-details.component';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {AddressModule} from '../address-component/address.module';
import {DigitalIdModule} from '../digital-id-component/digital-id.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {BusinessAppComponent} from './business/business-app.component';
import {BusinessQuoteComponent} from './business/business-quote.component';
import {AssetSettlementComponent} from './asset/asset-settlement.component';
import {AssetCreditComponent} from './asset/asset-credit.component';
import {ConsumerAppComponent} from './consumer/consumer-app.component';
import {ConsumerQuoteComponent} from './consumer/consumer-quote.component';
import {InsuranceAppComponent} from './insurance/insurance-app.component';
import { CorporateLoansAppComponent } from './corporate-loans/corporate-loans-app.component';
import {AuthorisedSignatoriesDetailsComponent} from './authorised-signatories-details.component';
import {PoliciesDetailsComponent} from './policies-details.component';
import {AssetCatAndTypeModule} from '../asset-cat-and-type-component/asset-cat-and-type.module';
import {DatepickerModule} from '../datepicker-component/datepicker.module';
import {MobileModule} from '../mobile-component/mobile.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { UploadFileModule } from '../upload-file-component/upload-file.module';
import {AssetDocumentsComponent} from './asset/asset-documents.component';
import {BusinessDocumentsComponent} from './business/business-documents.component';
import {BusinessCreditComponent} from './business/business-credit.component';
import {InsuranceDocumentsComponent} from './insurance/insurance-documents.component';
import { CorporateLoansDocumentsComponent } from './corporate-loans/corporate-loans-documents.component';
import {Observable} from 'rxjs';
import {ApiResponse, Base64File, RiskRatingInterface} from '@portal-workspace/grow-shared-library';
import {Application, ApplicationTypes, RatecardDetails, riskRating} from '@portal-workspace/grow-shared-library';
import {UserSelectionModule} from '../user-selection-component/user-selection.module';
import {BusinessOverdraftApplicationDetailsComponent} from './business-overdraft/business-overdraft-application-details.component';
import {BusinessOverdraftAppComponent} from './business-overdraft/business-overdraft-app.component';
import {BusinessOverdraftDocumentsComponent} from './business-overdraft/business-overdraft-documents.component';
import {BusinessOverdraftQuoteComponent} from './business-overdraft/business-overdraft-quote.component';
import { RiskAnalysisModule } from '../risk-analysis-component/risk-analysis.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

import {EmailModule} from '../common fields/email.module';
import {NameModule} from '../name-component/name.module';
import {AssetListingModule} from '../asset-listing-component/asset-listing.module';
import { DocumentDetailsSubTableComponent } from './document-details-sub-table.component';
import {CustomPaginatorModule} from '../custom-paginator-component/custom-paginator.module';
import { ConsumerAssetDocumentsComponent } from './consumer/consumer-documents.component';
import {KycVerificationComponent} from './kyc-verification.component';
import {YesNoModule} from '../yes-no-component/yes-no.module';
import {ApplicationStageIconComponent} from './application-stage-icon.component';
import {BusinessSettlementComponent} from './business/business-settlement.component';
import {BusinessNumberSearchModule} from '../business-number-search-component/business-number-search.module';
import { BankStatementModule } from '../bank-statement-component/bank-statement.module';
import {AssetPricingComponent} from "./asset/asset-pricing.component";
import {BusinessPricingComponent} from "./business/business-pricing.component";
import {BusinessOverdraftPricingComponent} from "./business-overdraft/business-overdraft-pricing.component";
import {BusinessOverdraftSettlementComponent} from "./business-overdraft/business-overdraft-settlement.component";
import {BusinessOverdraftCreditComponent} from './business-overdraft/business-overdraft-credit.component';
 
import {CorporateLoansPricingComponent} from './corporate-loans/corporate-loans-pricing.component';
import {CorporateLoansSettlementComponent} from './corporate-loans/corporate-loans-settlement.component';
import {CorporateLoansQuoteComponent} from './corporate-loans/corporate-loans-quote.component';

import {ConsumerPricingComponent} from "./consumer/consumer-pricing.component";
import {DscrCalculatorComponent} from "./dscr-calculator.component";
import {ConsumerCreditComponent} from "./consumer/consumer-credit.component";
import { CreditModule } from '../credit-component/credit.module';
import { BankStatementComponent } from '../bank-statement-component/bank-statement.component';

export type UpdateApplicationDocumentFn = (applicationId: number, files: File[] | Base64File[], deleteFiles: string[]) => Promise<Observable<any>>;


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        ApplicationSummaryModule,
        BreadcrumbModule,
        ChartModule,
        MessageBoxModule,
        TitleSelectionModule,
        AddressModule,
        DigitalIdModule,
        InputMaskModule,
        AssetCatAndTypeModule,
        DatepickerModule,
        MobileModule,
        ClipboardModule,
        UploadFileModule,
        UserSelectionModule,
        RiskAnalysisModule,
        AngularMaterialLibraryModule,
        PipeLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        NameModule,
        AssetListingModule,
        CustomPaginatorModule,
        CustomPaginatorModule,
        YesNoModule,
        BusinessNumberSearchModule,
        BankStatementModule,
        CreditModule,
        IndividualDetailsComponent,
        PrimaryContactDetailsComponent,
        ReferencesDetailsComponent,
        CorporateLoansBackgroundDetailsComponent,
        DocumentDetailsComponent,
        AuthorisedSignatoriesDetailsComponent,
        PoliciesDetailsComponent,
        KycVerificationComponent,
        AssetAppComponent,
        AssetQuoteComponent,
        AssetSettlementComponent,
        AssetCreditComponent,
        AssetDocumentsComponent,
        AssetFinanceApplicationDetailsComponent,
        AssetPricingComponent,
        BankStatementComponent,
        BusinessAppComponent,
        BusinessQuoteComponent,
        BusinessDocumentsComponent,
        BusinessCreditComponent,
        BusinessFinanceApplicationDetailsComponent,
        BusinessPricingComponent,
        ConsumerAppComponent,
        ConsumerQuoteComponent,
        ConsumerFinanceApplicationDetailsComponent,
        ConsumerAssetDocumentsComponent,
        ConsumerPricingComponent,
        ConsumerCreditComponent,
        InsuranceAppComponent,
        InsuranceDocumentsComponent,
        InsurancePremiumApplicationDetailsComponent,
        CorporateLoansAppComponent,
        CorporateLoansApplicationDetailsComponent,
        CorporateLoansDocumentsComponent,
        CorporateLoansPricingComponent,
        CorporateLoansSettlementComponent,
        CorporateLoansQuoteComponent,
        BusinessOverdraftAppComponent,
        BusinessOverdraftDocumentsComponent,
        BusinessOverdraftQuoteComponent,
        BusinessOverdraftApplicationDetailsComponent,
        BusinessOverdraftPricingComponent,
        DocumentDetailsSubTableComponent,
        ApplicationStageIconComponent,
        BusinessSettlementComponent,
        BusinessOverdraftSettlementComponent,
        DscrCalculatorComponent,
        BusinessOverdraftCreditComponent,
    ],
    exports: [
        ApplicationSummaryModule,
        BreadcrumbModule,
        ChartModule,
        MessageBoxModule,
        TitleSelectionModule,
        AddressModule,
        DigitalIdModule,
        InputMaskModule,
        AssetCatAndTypeModule,
        DatepickerModule,
        MobileModule,
        ClipboardModule,
        UploadFileModule,
        UserSelectionModule,
        RiskAnalysisModule,
        AngularMaterialLibraryModule,
        PipeLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        NameModule,
        AssetListingModule,
        CustomPaginatorModule,
        CustomPaginatorModule,
        YesNoModule,
        BusinessNumberSearchModule,
        BankStatementModule,
        CreditModule,
        IndividualDetailsComponent,
        PrimaryContactDetailsComponent,
        ReferencesDetailsComponent,
        CorporateLoansBackgroundDetailsComponent,
        DocumentDetailsComponent,
        AuthorisedSignatoriesDetailsComponent,
        PoliciesDetailsComponent,
        KycVerificationComponent,
        AssetAppComponent,
        AssetQuoteComponent,
        AssetSettlementComponent,
        AssetCreditComponent,
        AssetDocumentsComponent,
        AssetFinanceApplicationDetailsComponent,
        AssetPricingComponent,
        BankStatementComponent,
        BusinessAppComponent,
        BusinessQuoteComponent,
        BusinessDocumentsComponent,
        BusinessCreditComponent,
        BusinessFinanceApplicationDetailsComponent,
        BusinessPricingComponent,
        ConsumerAppComponent,
        ConsumerQuoteComponent,
        ConsumerFinanceApplicationDetailsComponent,
        ConsumerAssetDocumentsComponent,
        ConsumerPricingComponent,
        ConsumerCreditComponent,
        InsuranceAppComponent,
        InsuranceDocumentsComponent,
        InsurancePremiumApplicationDetailsComponent,
        CorporateLoansAppComponent,
        CorporateLoansApplicationDetailsComponent,
        CorporateLoansDocumentsComponent,
        CorporateLoansPricingComponent,
        CorporateLoansSettlementComponent,
        CorporateLoansQuoteComponent,
        BusinessOverdraftAppComponent,
        BusinessOverdraftDocumentsComponent,
        BusinessOverdraftQuoteComponent,
        BusinessOverdraftApplicationDetailsComponent,
        BusinessOverdraftPricingComponent,
        DocumentDetailsSubTableComponent,
        ApplicationStageIconComponent,
        BusinessSettlementComponent,
        BusinessOverdraftSettlementComponent,
        DscrCalculatorComponent,
        BusinessOverdraftCreditComponent,
    ]
})
export class ApplicationDetailsModule {

}
